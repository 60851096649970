import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
// import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private readonly document: Document, private readonly rendererFactory: RendererFactory2) {
    /**
     * You cannot inject Renderer2, but we can run RendererFactory2 to get Renderer2 instance inside @Injectable() service.
     *
     * @link https://stackoverflow.com/questions/34710408/renderer-cannot-be-used-in-service
     **/
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private readonly renderer: Renderer2;

  createCannonicaUrl(url: string, hasDomain = false): void {
    return;
    // if (environment.production) {
    //   const LINK_ELEMENT = this.renderer.createElement('link');
    //
    //   LINK_ELEMENT.setAttribute('rel', 'canonical');
    //
    //   const EXISTING_LINK = this.document.head.querySelector('[rel="canonical"]');
    //
    //   EXISTING_LINK ? this.document.head.replaceChild(LINK_ELEMENT, EXISTING_LINK) : this.renderer.appendChild(this.document.head, LINK_ELEMENT);
    //
    //   LINK_ELEMENT.setAttribute('href', hasDomain ? url : `${environment.appUrl}/${url}`);
    // }
  }

  /**
   * Remove canonical link element
   */
  removeCanonicalLinkElement(): void {
    const EXISTING_LINK = this.document.head.querySelector('[rel="canonical"]');

    if (EXISTING_LINK) {
      EXISTING_LINK.remove();
    }
  }
}
